*{
    box-sizing: border-box;
    margin: 0;
}

.navbar{
    z-index: 2;
}

.navbar-brand img{
    width: 150px;
    height: 100px;
    background-color: #eba44e;
    padding: 2px;
    border-radius: 5px;
}

/* .navbar{
    padding:  5% 2% 5% 2%;
} */

.navbar-expand-lg .navbar-collapse{
    justify-content: flex-end;
}

.navbar-light .navbar-nav .nav-link{
    text-decoration: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #ffffff;
    float: left;
    border: 2px solid #464b50;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}
.bg-light{
    background-color: #464b50!important;
}
.navbar-light .navbar-nav .nav-link:hover{
    color: #eba44e;
}
.dropdown-item{
    text-decoration: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #ffffff;
    float: left;
    border: 2px solid #464b50;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    background-color: #eba44e;
}

@media screen and (max-width: 990px) {
    .dropdown-menu{
        padding: 0;
    }
}

.intro-img{
    position: relative;
    font-family: monospace, sans-serif;
}

.intro-img img{
    width: 100%;
    height: auto;
}

.intro-img .intro-img-content{
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.3); 
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
}



.para-content{
    margin: 25px 150px 25px 150px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    line-height: 30px;
    color: #61615f;
    overflow: auto;
}

@media screen and (max-width: 800px) {
    .para-content{
        margin: 10px 10px 10px 10px;
        font-size: 17px;
    }
    .accordion-div{
        margin: 10px 10px 10px 10px;
        font-size: 17px;
    }
}

@media screen and (max-width: 800px) {
    .para-content .hm{
        display: block;
    }
    .para-content .hw{
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .para-content .hm{
        display: none;
    }
    .para-content .hw{
        display: block;
    }
}



.para-content .para-img1{
    float: left;
}

.para-content .para-img2{
    float: right;
}

.home-page .para-content{
    margin: 25px 0px 25px 0px;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    line-height: 30px;
    color: #61615f;
}

.para-content h3{
    margin: 10px 10px 10px 10px;
}

.para-content img{
    width: 200px;
    height: 200px;
    margin: 0px 20px 0px 20px;
}

.material-symbols-outlined{
    color: #eba44e;
}


.contact-header li{
    background-color: #ffffff;
    list-style: none;
    float: left;
    padding: 0px 0px 0px 15px;
}

.contact-header ul{
    margin: 0;
    overflow: hidden;
}

.contact-header{
    width: 100%;
    overflow: hidden;
    padding: 10px 0px 0px 0px;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .contact-header{
        display: none;
    }
}


@media screen and (max-width: 600px){
    .home-page .para-content {
    margin: 10px 10px 10px 10px;
    font-size: 17px;
}
}


.footer{
    width: 100%;
    background-color: #464b50;
    overflow: hidden;
    color: white;
}

.footer h3{
    color: #eba44e;
    font-family: cursive;
    letter-spacing: 1px;
    padding-bottom: 10px;
}

.footer-div{
    width: 25%;
    float: left;
    padding: 20px;
    text-align: center;
    
}

@media screen and (max-width: 800px) {
    .footer-div{
        width: 100%;
    }
}

.footer-div i{
    padding: 10px;
    font-size: x-large;
    cursor: pointer;
    display: inline-block;
}

.footer a{
    text-decoration: none;
    float: none;
    display: block;
    color: rgb(221, 233, 240);
    line-height: 25px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: Inter,ui-sans-serif,system-ui,sans-serif;
    font-size: 15px;
}

.footer a:hover{
    color: #eba44e;
}


.cpryt{
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #464b50;
    color: #f1f1f1;
    font-family: monospace;
    text-decoration: none;
}

@media screen and (max-width: 600px){
    .scrb input[type=submit] {
    width: 50%;
    margin-top: 0;
}}

@media screen and (max-width: 600px){
    .scrb input[type=submit] {
    width: 50%;
    margin-top: 0;
}}

.sh a{
    text-align: right;
    background-color: #464b50;
    color: #f1f1f1;
    text-decoration: none;
    letter-spacing: 1px;
    font-family: 'Quicksand', sans-serif;
    padding: 5px;
}

.sh{
    width: 100%;
    text-align: right;
    background-color: #464b50;
    color: #f1f1f1;
    text-decoration: none;
}

.contact-header a{
    text-decoration: none;
    color: #111111;
}

.footer-div li{
    display: inline-block;
}

.footer ul{
    padding: 0;
}

.i-fdiv a{
    display: inline-block;
}

.osdiv .material-symbols-outlined:hover{
    color: #e67f03;
    font-weight:bolder;
}



/* for stick wtsp icon */


body {margin:0;}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.wtsp {
  background: #49c557;
  border-top-right-radius: 10px;
  border-bottom-right-radius:  10px;
}

.wtsp:hover {
  background: white;
}

.wtsp:hover {
  background: white;
}


.content {
  margin-left: 75px;
  font-size: 30px;
}
div.mapdiv {
  display: flex; 
  align-items: center;
}
.mapdiv-left {
  width: 50%; 
  text-align: center;
}

.mapdiv-right {
  width: 50%; 
  text-align: center;
}

.mapdiv iframe{
  width: 100%;
  height: 500px;
}

.mapdiv{
  margin: 100px 0px 100px 100px;
}

.mapdiv span{
  font-size: 50px;
}

@media only screen and (max-width: 800px) {
  .mapdiv-left {
    width: 100%; 
    text-align: center;
  }
  .mapdiv-right {
    width: 100%; 
    text-align: center;
  }
  .mapdiv{
    margin: 0px;
  }
  div.mapdiv {
    display: block; 
    margin: 50px 0px 50px 0px;
  }
}

.form-heading{
  margin: auto;
  text-align: center;
  padding: 15px;
}

.home-page{
  margin: 50px 100px 0px 100px;
}

.form-container{
  margin: 50px 100px 0px 100px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  padding: 10px 50px 10px 50px;
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 5px;
}

@media only screen and (max-width: 800px) {
  .home-page {
    margin: 0px 0px 0px 0px;
  }
  .form-container{
    margin: 0px 0px 0px 0px;
    padding: 10px;
  }
}

.mySlides {display: none;}

.slideshow-container {
  position: relative;
  margin: auto;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

.glry{
  display: inline-block;
  margin: 10px 0px 0px 0px;
}

div.gallery {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
  }

  div.gallery img {
    width: 100%;
    height: auto;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
  }
  
  @media only screen and (max-width: 700px) {
    .responsive {
      width: 49.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

div.desc button{
  color: #000000;
}

div.desc button, .acc-div1 button, .para-content button{
  align-items: center;
  background-clip: padding-box;
  background-color: #eba44e;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  letter-spacing: 1px;
}

div.desc button:hover,
div.desc button:focus,
.para-content button:hover,
.para-content button:focus{
  box-shadow: rgba(0, 0, 0, 1) 0 4px 12px;
}

.para-content button{
  margin: 0% 0% 0% 25%;
}

.acc-div1 button:hover,
.acc-div1 button:focus {
  box-shadow: rgb(255, 255, 255) 0 4px 12px;
}

div.desc button:hover {
  transform: translateY(-1px);
}

.form-container input[type=text],
.form-container  input[type=number],
.form-container  input[type=email],
.form-container  select,
.form-container textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.form-container input[type=submit] {
  padding: 12px 20px;
  float: right; 
  align-items: center;
  background-clip: padding-box;
  background-color: #eba44e;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0 1px 3px 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

input[type=submit]:hover {
  transform: translateY(-1px);
  box-shadow: rgba(0, 0, 0, 1) 0 4px 12px;
}

.container {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;
  cursor: pointer;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}

  .img-slider {
    width: 100%;
    position: relative;
    margin: auto;
  }
  .img-slider span {
    top: 50%;
    height: 50px;
    width: 50px;
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    text-align: center;
    line-height: 50px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.23);
    transform: translateY(-50%);
    transition: transform 0.1s linear;
    font-size: 50px;
    color: #ffffff;
  }
  .img-slider span:active{
    transform: translateY(-50%) scale(0.85);
  }
  .img-slider span:first-child{
    left: 10px;
  } 
  .img-slider span:last-child{
    right: 10px;
  }
  .img-slider .img-ul{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 4) - 12px);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 16px;
    border-radius: 8px;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding: 0;
  }
  .img-ul::-webkit-scrollbar {
    display: none;
  }
  .img-ul.no-transition {
    scroll-behavior: auto;
  }
  .img-ul.dragging {
    scroll-snap-type: none;
    scroll-behavior: auto;
  }
  .img-ul.dragging .img-li {
    cursor: grab;
    user-select: none;
  }
  .img-ul :where(.img-li, .img) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-ul .img-li {
    scroll-snap-align: start;
    height: 342px;
    list-style: none;
    background: #868080;
    cursor: pointer;
    flex-direction: column;
    border-radius: 8px;
  }
  .img-ul .img-li .img {
    background: #8B53FF;
    height: 100%;
    width: 100%;
  }
  .img-li .img img {
      height: 100%;
      width: 100%;
    object-fit: cover;
    border: 4px solid #fff;
  }
  
  @media screen and (max-width: 900px) {
    .img-slider .img-ul {
      grid-auto-columns: calc((100% / 2) - 9px);
    }
  }
  
  @media screen and (max-width: 600px) {
    .img-slider .img-ul {
      grid-auto-columns: 100%;
    }
  }

  .accordion-div{
    margin: 50px 150px 50px 150px;
    font-family: 'Nunito Sans', sans-serif;
    line-height: 30px;
    line-height: 20px;
    letter-spacing: 1px;
    font-size: smaller;
  }

  .accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  font-family: cursive;
  font-weight: 600;
}

.active, .accordion:hover {
  background-color: #eba44e;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.acc-div1{
  width: 46%;
  float: left;
  margin: 10px;
  background-color: #262222;
  padding: 15px;
  color: white;
  font-weight: 500;
  border-radius: 3px;
}

.enqry{
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
}

@media screen and (max-width: 600px) {

  .accordion-div{
      margin: 10px 10px 10px 10px;
      font-size: 17px;
  }
}

@media screen and (max-width: 800px) {
  .acc-div1{
    width: 95%;
    float: none;
    margin: 10px;
    background-color: #262222;
    padding: 10px;
    color: white;
    font-weight: 500;
  }
}


.home-page h3{
  margin: 10px;
  font-family: monospace, sans-serif;
}

.gridgallary .header {
  text-align: center;
  padding: 32px;
}

.gridgallary .row {
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-wrap: wrap; 
  flex-wrap: wrap;
  padding: 0 4px;
}

.gridgallary .column {
  -ms-flex: 25%; 
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.gridgallary .column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .gridgallary .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .gridgallary .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}


.osdiv{
  overflow: hidden;
}

.osdiv .material-symbols-outlined{
  font-size: 50px;
}

div.os-gallery {
  text-align: center;
  
}

div.os-gallery span {
  width: 100%;
  height: auto;
}

div.os-desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.os-responsive {
  padding: 0 6px;
  float: left;
  width: 16.555%;
}

@media only screen and (max-width: 700px) {
  .os-responsive {
    width: 32.99999%;
    margin: 6px 0;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.scrb{
  padding: 15px;
  background-color: #eba44e;
  display: flex;
  justify-content: space-evenly;
}


.scrb input[type=text], .scrb input[type=email] {
  width: 25%;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 20px;
  resize: vertical;
}

@media only screen and (max-width: 600px) {
  .scrb input[type=text], .scrb input[type=email] {
    width: 100%;
    margin: 6px 0;
  }

  .scrb{
    display: block;
    text-align: center;
  }
}

.scrb-h{
    font-weight: 500;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    color: #000;
    padding: 7px;
}

.scrb input[type=submit] {
background-color: #000;
color: white;
border: 1px solid #000;
padding: 10px 20px;
font-family: 'Quicksand', sans-serif;
font-weight: 700;
margin: 0px 20px;
}




@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}






.uppr{
  margin: 20px 150px 20px 150px;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  line-height: 20px;
  font-weight: 500;
}

.uppadd{
  width: 50%;
  float: left;
  padding: 10px;
  text-align: center;
}

.uppadd a{
  text-decoration: none;
  color: #000;
}


@media screen and (max-width: 800px) {
  .uppr{
      margin: 0;
      display: inline-block;
      margin-bottom: 20px;}

  .uppadd{
        width: 100%;
        float: left;
        padding: 10px;
        text-align: center;
      }
  }

.clnt{
  margin: 50px;
  text-align: center;
}

#mp{
  font-size: 50px;
}

#lp{
  font-size: 30px;
}

.home-page .para-content button{
  margin: 10px;
}

.ow{
  font-size: large;
  font-weight: 700;
  float: right;
}

.accordion-body {
  padding: 0;
}


.container{
  display: flex;
}


.testimonials{
  padding: 10%;
}




 .homemain .swiper-button-prev:after, .homemain .swiper-button-next:after{
    display: none;
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal 
{
  display: none;
}


.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10%);
  right: auto;
}

